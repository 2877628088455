import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/StructureComponents/Layout'
import FeaturedBlogCardOne from '../../components/PageComponents/FeaturedBlogCardOne'
import { Col, Container, Row } from 'react-grid-system'
import { nanoid } from 'nanoid'
import BlogCardOne from '../../components/PageComponents/BlogCardOne'
import BlogcardsideBar from '../../components/PageComponents/BlogcardsideBar'
import Header from '../../components/shared/Header'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import _ from 'lodash'

const AuhorPage = ({
  data: { page, blogs, blogCategories, indexPage, blogSideBarSections },
  location
}) => {
  const [blogState, setBlogState] = useState([...blogs.edges])
  const [renderBlogs, setRenderBlogs] = useState(false)
  const allBlogsRef = useRef()
  const categoryToFilter = location.pathname.includes('/category/')
    ? location.pathname.replace('/category/', '').replace('/', '')
    : ''
  const [author, setAuthor] = useState()

  allBlogsRef.current = [...blogs.edges]

  // console.log('categories to filter are ', categoryToFilter)

  const filterBlogsAccordingToCategories = () => {
    if (categoryToFilter !== '') {
      setBlogState(() => {
        let filteredBlogs = [...blogState].filter((blog) => {
          const categoryListToCheck = blog?.node?.frontmatter?.categories
          if (categoryListToCheck) {
            return categoryListToCheck
              .map((item) => _.kebabCase(item))
              .includes(categoryToFilter)
          }
          return false
        })

        return filteredBlogs
      })
    }
  }

  useEffect(() => {
    filterBlogsAccordingToCategories()

    return () => setBlogState([])
  }, [categoryToFilter])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentAuthor = _.startCase(
        window.location.pathname.replaceAll('author', '').replaceAll('/', '')
      )
      const filteredBlogs = [...blogs.edges].filter(
        (item) => item.node.frontmatter.author === currentAuthor
      )

      setAuthor(currentAuthor)
      setBlogState(filteredBlogs)
    }

    setTimeout(() => {
      setRenderBlogs(true)
    }, 10)
  }, [])

  const metaToShow = {
    ...page.frontmatter.meta,
    canonicalLink: `/author/${_.kebabCase(page?.frontmatter?.slug)}` || '/'
  }
  return (
    <Layout meta={metaToShow || false} title={page.frontmatter.title || false}>
      <Header />
      <ThemeChanger />
      <section className="blogPage">
        <Container className="blogs____sectioncontainer">
          <div className="blogs_top_Div">
            <div className="maxWidthcontainer">
              <h1 className="blogPage__heading">
                {categoryToFilter === ''
                  ? author !== ''
                    ? `Author : ${author}`
                    : 'Blogs'
                  : 'Category'}
              </h1>

              {blogState.length > 0 ? (
                <FeaturedBlogCardOne {...blogState[0].node.frontmatter} />
              ) : (
                <h1>No Blogs Fount!</h1>
              )}
            </div>
          </div>

          {renderBlogs && (
            <div className="blogPage__blogCards">
              <div className="maxWidthcontainer">
                <Container className="blogs__pagecontainer">
                  <Row>
                    <Col lg={9}>
                      <Container className="blogscard___cantainer">
                        <Row>
                          {[...blogState]
                            .slice(1)
                            .slice(0)
                            .map((edge) => (
                              <Col
                                key={nanoid()}
                                lg={4}
                                md={6}
                                sm={12}
                                className="blogs__cardscaontainer"
                              >
                                {blogState.length > 1 && (
                                  <BlogCardOne
                                    {...edge.node.frontmatter}
                                    html={edge.node.html}
                                  />
                                )}
                              </Col>
                            ))}
                        </Row>
                      </Container>
                    </Col>
                    <Col lg={3} className="blogs____pagscontainer">
                      <div>
                        {blogSideBarSections.edges.map(
                          ({ node: { frontmatter, html } }) => (
                            <BlogcardsideBar
                              html={html}
                              sectionButton={frontmatter.sectionButton}
                              title={frontmatter.title}
                            />
                          )
                        )}
                      </div>

                      <div className="blogs__category__container">
                        <div className="blogs_category__title">Category</div>
                        <hr style={{ color: '#484848' }} />

                        {blogCategories.edges.map((cat) => (
                          <>
                            {cat?.node?.frontmatter?.title && (
                              <a
                                href={`/category/${_.kebabCase(
                                  cat?.node?.frontmatter?.title
                                )}/`}
                                className={`${
                                  _.kebabCase(cat?.node?.frontmatter?.title) ===
                                  categoryToFilter
                                    ? 'blogs___categoriestitles'
                                    : 'catgoery____headings'
                                }`}
                              >
                                {cat?.node?.frontmatter?.title}
                              </a>
                            )}
                          </>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )}
        </Container>
      </section>

      <FooterSection {...indexPage.frontmatter.footersection} />
    </Layout>
  )
}
export default AuhorPage

export const pageQuery = graphql`
  ## Query for AuhorPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AuhorPage($id: String!) {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }

    page: markdownRemark(id: { eq: $id }) {
      ...Meta

      fields {
        contentType
      }
      frontmatter {
        title
        slug
        template
        subtitle

        meta {
          title
          description
          ogimage
          canonicalLink
        }
      }
    }

    blogs: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blogs" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            date(formatString: "MMM D YYYY")
            slug
            categories
            status

            author
            featuredimage {
              image
              alt
            }
          }
        }
      }
    }
    blogCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blogCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    blogSideBarSections: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blogPageSideSections" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            sectionButton {
              buttonText
              buttonLink
            }
          }
        }
      }
    }
  }
`
