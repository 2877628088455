import React from 'react'
import _ from 'lodash'
import { navigate } from '@reach/router'
const BlogCardOne = ({
  date = 'Mar 7, 2022',
  slug,
  title = 'Learn software development a step by step guide',
  author = 'John Doe',
  html = `The problem with today's society is the abundance and instant nature of data. Organization, prioritization and retention of all of this data is near impossible. If you use a complex tool to try to manage these problems it doesn't solve the underlying problems, it creates new ones.`,
  featuredimage = { image: '/images/bitmap.png', alt: 'blog image' },
  categories = ['no Tag']
}) => {
  return (
    <div
      className="blogCardOne"
      onClick={() => {
        navigate(`/blog/${(slug || title).toLowerCase()}/`)
      }}
    >
      <img
        className="blogCardOne__img"
        src={featuredimage.image}
        alt={featuredimage.alt}
      />

      <div className="blogCardOne__metaContainer">
        <div className="blogCardOne__tags">
          {categories.map((tag) => (
            <a
              href={`/category/${_.kebabCase(tag)}/`}
              className="blogCardOne__tag"
              key={tag}
            >
              {tag}
            </a>
          ))}
        </div>
        <div className="blogCardOne__publishedDate">{date}</div>
      </div>

      <h2 className="blogCardOne__title">{title}</h2>

      <div
        className="blogCardOne__description"
        dangerouslySetInnerHTML={{
          __html: html?.substring(0, 100)
        }}
      />

      <a
        href={`/blog/${(slug || title).toLowerCase()}/`}
        className="blogCardOne__link"
      >
        Read More
        <span className="blogCardOne__arrow">&#8594;</span>
      </a>
    </div>
  )
}

export default BlogCardOne
