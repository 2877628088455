import React from 'react'
import { Col } from 'react-grid-system'
function BlogcardsideBar({
  title = 'title',
  html = '<p>description</p>',
  sectionButton = { buttonText: 'Get', buttonLink: '/' }
}) {
  return (
    <Col
      md={12}
      lg={12}
      sm={12}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
      className="blogs__cardc"
    >
      <div className="blog__cardsidebar">
        <p className="blogs__heading">{title}</p>
        <div className=" "></div>
        <div
          className="blogs___paragrapgh"
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
        <a className="blogsbutton" href={`${sectionButton.buttonLink}`}>
          {sectionButton.buttonText}
        </a>
      </div>
    </Col>
  )
}

export default BlogcardsideBar
